import React from 'react'
import logo from '../assets/img/logo.png'
import { Typography } from '@mui/material'

import instaIcon from "../assets/img/Social Icons.png";
import facebookIcon from "../assets/img/facebook.png";
import twitterIcon from "../assets/img/twitter.png";

import playStoreIcon from "../assets/img/image 4.png";
import appStoreIcon from "../assets/img/image 5.png";

import asset80 from "../assets/img/Asset 80.png";
import asset90 from "../assets/img/Asset 90.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo-section">
          <div style={{display:"flex",flexDirection:"column",width:"160px"}}>
          <img  src={logo} alt="CSPDCL Logo" className="logo" 
          // className="footer-logo" 
          />
          <Typography sx={{fontSize:"10px",textAlign:"center",fontFamily:"Poppins"}}>Chhattisgrah State Power </Typography>
          <Typography sx={{fontSize:"10px",textAlign:"center",lineHeight:"2px",fontFamily:"Poppins"}}> Distribution Company Limited</Typography>
          </div>
         
          <Typography sx={{fontSize:"16px",marginTop:"20px !important",fontFamily:"Poppins"}}>
            The Chhattisgarh State Power Distribution Company Limited (CSPDCL) is a state-owned utility company in Chhattisgarh, India, responsible for electricity distribution.
          </Typography>
          <br />
          <Typography sx={{fontSize:"16px",marginTop:"10px !important",fontFamily:"Poppins"}}><strong className='common_font'>GST Identification No.</strong> - 22AADCC6047K1ZR</Typography>
          <Typography sx={{fontSize:"16px",marginTop:"10px !important",fontFamily:"Poppins"}}><strong>Registration No.</strong> - U40108 CT 2003 PLC 15822</Typography>
          <div style={{marginTop:"10px !important",display:"flex",flexDirection:"row",alignItems:"center"}}>
          <Typography sx={{fontSize:"16px",marginTop:"10px !important",fontFamily:"Poppins"}}><strong>Follow us on</strong></Typography>
          <div className="social-icons">
            <img src={facebookIcon} style={{width:"22px"}} alt="Facebook" />
            <img src={twitterIcon} style={{width:"22px"}} alt="Twitter" />
            <img src={instaIcon} style={{width:"22px"}} alt="Instagram" />
          </div>
          </div>
         
        </div>

        <div className="footer-links-section">
          <h3 className='common_font'>Important links</h3>
          <ul>
            <li className='common_font' style={{marginTop:"20px"}}><a href="#">Sitemap</a></li>
            <li className='common_font' style={{marginTop:"20px"}}><a href="#">Consumer e-seva</a></li>
            <li className='common_font' style={{marginTop:"20px"}}><a href="#">Consumer services</a></li>
            <li className='common_font' style={{marginTop:"20px"}}><a href="#">Online new connection</a></li>
            
            <li className='common_font' style={{marginTop:"20px"}}><a href="#">Solar rooftop portal</a></li>
            <li className='common_font' style={{marginTop:"20px"}}><a href="#">Employee app download</a></li>
          </ul>
          <div className="app-store-links">
            <img src={playStoreIcon} style={{width:"80px"}} alt="Google Play" />
            <img src={appStoreIcon} style={{width:"80px"}}  alt="App Store" />
          </div>
        </div>

        <div className="footer-help-support-section">
          <h3 className='common_font'>Help & Support</h3>
          <p className='common_font'>टेलीफोन नंबर: 0771 - 2574166</p>
          <p className='common_font'>फैक्स नंबर : 0771 - 2574125</p>
          <p className='common_font'>ई-मेल: customercare912@cspc.co.in</p>
          <div className="certification-logos">
            <img src={asset80} style={{width:"30px"}}  alt="STQC" />
            <img src={asset90} alt="GIW" />
          </div>
        </div>

        <div className="footer-contact-section">
          <h3 className='common_font'>Contact us</h3>
          <p className='common_font'>मुख्य अभियंता, ऊर्जा सूचना प्रौद्योगिकी केंद्र,बीड-नं. 8, ए.डी.बी. भवन, कंपनी. केम्पस, डंगनिया, रायपुर (छ.ग.)</p>
          <p className='common_font'>टेलीफोन नंबर: 0771 - 2574166</p>
          <p className='common_font'>फैक्स नंबर : 0771 - 2574125</p>
        </div>
      </div>

      <div className="footer-bottom">
        <p className='common_font'>Last Update on : <strong>05-08-2024</strong></p>
        <p className='common_font'>© cspdcl.co.in</p>
        <p className='common_font'>Total Visitors - <strong>10967876</strong></p>
      </div>
    </footer>
  )
}

export default Footer