import React from 'react'
import logo from '../assets/img/logo.png'

const MainHeader = () => {
  return (
    <div className="header-container">
      <div className="logo-section">
        <img src={logo} alt="CSPDCL Logo" className="logo" />
        {/* <div className="logo-text"> */}
          <span className='logo-text' style={{textAlign:"center"}}>Chhattisgarh State Power</span>
          <span className='logo-text' style={{textAlign:"center"}}>Distribution Company Limited</span>
        {/* </div> */}
      </div>
      <div className="navigation-section">
        <nav className="nav-links">
          <a href="#" className="main-header-nav-link main-header-spl-txt">Home</a>
          <a href="#" className="main-header-nav-link main-header-txt" >Dashboard</a>
          <a href="#" className="main-header-nav-link main-header-txt">Employee login</a>
          <a href="#" className="main-header-nav-link main-header-txt">About CSPDCL</a>
        </nav>
        <button className="contact-button">Contact us</button>
      </div>
    </div>
  )
}

export default MainHeader