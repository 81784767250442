import logo from './logo.svg';
import './App.css';

import {
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { BrowserRouter } from "react-router-dom/dist";
import HomePage from './components/HomePage';
import Layout from './components/Layout';

function App() {
  return (
    <div>
      <HomePage/>
    {/* <BrowserRouter >
      <Routes>
        <Route path='/' element={<Navigate to="/home" replace/>} />
       <Route  path="/" element={<Layout/>}>
       <Route path='/home' element={<HomePage/>}/>
       </Route>
      </Routes>

    </BrowserRouter> */}
    </div>
  );
}

export default App;
