import React from "react";
import ContactHeader from "./ContactHeader";
import MainHeader from "./MainHeader";
import NavigationHeader from "./NavigationHeader";
import mainImg from "../assets/img/Property 1=banner 1.png";
// import MainPage from "./MainPage";
import solarfimg from "../assets/img/image 6.png";
import donwloadImg from "../assets/img/Frame 29.png";

import image10 from "../assets/img/image 10.png";
import image9 from "../assets/img/image 9.png";

import image50 from "../assets/img/Frame 50.png";
import image54 from "../assets/img/Frame 54.png";
import image53 from "../assets/img/Frame 53.png";

import image52 from "../assets/img/Frame 52.png";
import image60 from "../assets/img/Frame60.png";
import image51 from "../assets/img/Frame 51.png";

import image57 from "../assets/img/Frame57.png";
import image58 from "../assets/img/Frame58.png";
import image59 from "../assets/img/Frame59.png";

import image2 from "../assets/img/image 2.png";
import image3 from "../assets/img/image 3.png"


import playStoreIcon from "../assets/img/image 4.png";
import appStoreIcon from "../assets/img/image 5.png";

import arrowImg from "../assets/img/arrow-right.png";

import { Box, Grid, Typography } from "@mui/material";
import Footer from "./Footer";
const HomePage = () => {
  const announcements = [
    {
      text: "छत्तीसगढ़ अनुज्ञापन मंडल (विनियम) 1960 (अनुकूलन 2000) के प्रावधान अंतर्गत पर्यवेक्षक परीक्षा 2023 दिनांक 22.08.2023 से 25.08.2023 को आयोजित मुख्य विद्युत निरीक्षक द्वारा आयोजित परीक्षा का मॉडल उत्तर",
      date: "22.08.2023 - 25.08.2023",
    },
    {
      text: "सार्वजनिक सूचना - विद्युत उपभोक्ताओं का सशक्तिकरण (Public Notice – Empowering Electricity Consumers)",
      date: "",
    },
    {
      text: "Click here for TDS login submission",
      date: "",
    },
    {
      text: "Schedule of rates 2023-24",
      date: "",
    },
  ];

  const stats = [
    {
      icon: "🏘️", // Placeholder for an icon
      number: "19588",
      description: "Electrified villages",
    },
    {
      icon: "⚡", // Placeholder for an icon
      number: "17661 Km",
      description: "33 KV lines",
    },
    {
      icon: "📶", // Placeholder for an icon
      number: "1614946",
      description: "BPL Connections",
    },
    {
      icon: "🔌", // Placeholder for an icon
      number: "33/11 KV",
      description: "Power Transformers",
    },
  ];

  return (
    <div>
      <ContactHeader />
      <MainHeader />
      <NavigationHeader />

      <div>
        <img src={mainImg} alt="img" className="main-img" />
      </div>

{/* information  */}

      <div className="announcements-container">
        <Grid container>
          <Grid md={4} lg={3.2}>
            <div className="announcement">
              <span className="new-label">New</span>
              <a href="#" className="announcement-text">
                छत्तीसगढ़ अनुज्ञापन मंडल (विनियम) 1960 (अनुकूलन 2000) के
                प्रावधान अंतर्गत पर्यवेक्षक परीक्षा 2023 दिनांक 22.08.2023 से
                25.08.2023 को आयोजित मुख्य विद्युत निरीक्षक द्वारा
                आयोजित परीक्षा का मॉडल उत्तर
              </a>
            </div>
          </Grid>

          <Grid md={4} lg={3.2}>
            <div className="announcement">
              <span className="new-label">New</span>
              <a href="#" className="announcement-text">
                सार्वजनिक सूचना - विद्युत उपभोक्ताओं का सशक्तिकरण (Public Notice
                – Empowering Electricity Consumers)
              </a>
            </div>
          </Grid>

          <Grid md={4} lg={3.2}>
            <div className="announcement">
              <span className="new-label">New</span>
              <a href="#" className="announcement-text">
                Click here for TDS login submission)
              </a>
            </div>
          </Grid>

          <Grid md={4} lg={2.4}>
            <div className="announcement">
              <span className="new-label">New</span>
              <a href="#" className="announcement-text">
                Schedule of rates 2023-24
              </a>
            </div>
          </Grid>
        </Grid>
      </div>
{/* payment & e-seva  */}
      {/* customer  */}

      <div className="payment-section">
        <div className="quick-bill">
          <h2
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "31px",
              textAlign: "center",
            }}
          >
            Quick bill payment
          </h2>
          <hr style={{ width: "100%",margin:"0px" }} />
          <div className="pay-bill-box">
            <img src={image50} alt="image" />
            <h3 style={{fontSize:"20px",fontWeight:"600"}} className="common_font">Pay your bill now</h3>
            <p style={{ fontSize: "16px", textAlign: "center",margin:"8px 0px !important" }}>
              Lorem ipsum dolor sit amet consectetur.
            </p>
            <input className="pay-bill-box-input common_font" type="text" placeholder="Enter your BP number" />
            <button className="pay-now-btn common_font">Pay now</button>
          </div>
        </div>

        <div className="e-seva">
          <h2
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "31px",
              textAlign: "center",
            }}
          >
            E-seva
          </h2>
          <hr style={{ width: "100%",margin:"0px"  }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <div className="e-seva-box">
            <img src={image53} alt="image" />
              <h3 style={{fontSize:"20px",fontWeight:"600"}} className="common_font">Consumer Registration</h3>
              <p className="common_font" style={{ fontSize: "16px", textAlign: "center" }}>
                Lorem ipsum dolor sit amet consectetur.
              </p>
              <button className="signup-btn common_font ">Sign up</button>
            </div>
            <div className="e-seva-box">
            <img src={image54} alt="image" />
              <h3 style={{fontSize:"20px",fontWeight:"600"}}>Consumer login</h3>
              <p style={{ fontSize: "16px", textAlign: "center" }}>
                Lorem ipsum dolor sit amet consectetur.
              </p>
              <button className="login-btn common_font">Login</button>
            </div>
          </div>
        </div>
      </div>

      {/* services-section */}

      <div className="services-section">
        <div className="service-box service-box-clr-1">
          <div>
            <img src={image52} alt="image" />
          </div>
          <div className="service-box-sub">
            <Typography sx={{fontSize:"25px",fontFamily:"Poppins",fontWeight:"600"}} className="common-font">Common service center</Typography>
            <Typography className="common_font" style={{textAlign:"start",fontSize:"16px",color:"#2B2B2B",fontFamily:"Poppins"}}>
              Lorem ipsum dolor sit amet consectetur. Auctor sed ac auctor ut
              arcu lectus ridiculus libero.
            </Typography>
            <a style={{color:"#274699",display:"flex",justifyContent:"center",alignItems:"center"}}>Know more <img style={{marginLeft:"10px"}} src={arrowImg} alt="arrowImg" /></a>
          </div>
        </div>
        <div className="service-box service-box-clr-2">
        <div>
            <img src={image60} alt="image" />
          </div>
          <div className="service-box-sub">
            <Typography sx={{fontSize:"25px",fontFamily:"Poppins",fontWeight:"600"}}>Pay point centers</Typography>
            <Typography  style={{textAlign:"start",fontSize:"16px",color:"#2B2B2B",fontFamily:"Poppins"}}>
              Lorem ipsum dolor sit amet consectetur. Auctor sed ac auctor ut
              arcu lectus ridiculus libero.
            </Typography>
            <a style={{color:"#274699",display:"flex",justifyContent:"center",alignItems:"center",fontFamily:"Poppins"}}>Know more <img style={{marginLeft:"10px"}} src={arrowImg} alt="arrowImg" /></a>
          </div>
        </div>
        <div className="service-box service-box-clr-3">
        <div>
            <img src={image51} alt="image" />
          </div>
          <div className="service-box-sub">
            <Typography sx={{fontSize:"25px",fontFamily:"Poppins",fontWeight:"600"}}>Pay through RTGS/NEFT</Typography>
            <Typography style={{textAlign:"start",fontSize:"16px",color:"#2B2B2B",fontFamily:"Poppins"}}>
              Lorem ipsum dolor sit amet consectetur. Auctor sed ac auctor ut
              arcu lectus ridiculus libero.
            </Typography>
            <a style={{color:"#274699",display:"flex",justifyContent:"center",alignItems:"center",fontFamily:"Poppins"}}>Know more  <img style={{marginLeft:"10px"}} src={arrowImg} alt="arrowImg" /></a>
          </div>
        </div>
      </div>

{/* solar section */}

      <div className="solar-section">
        <div className="solar-sub-section">
          <div style={{ margin: "0px 40px" }}>
            <Typography className="common-font" style={{ fontSize: "39px ",fontWeight:"600",fontFamily:"Poppins" }}>
              Apply for kusum yojna today <br />
              and Get Solar panels
            </Typography>
            <Typography style={{ fontSize: "16px",textAlign:"start",color:"#FFFFFF",fontFamily:"Poppins" }}>
              Lorem ipsum dolor sit amet consectetur. Tristique fermentum nunc volutpat amet eleifend elit proin turpis. Sit proin arcu in facilisis. Enim vehicula cras sit sit magna diam. Tincidunt dui facilisis nisl justo gravida hendrerit quisque.
            </Typography>
            <button className="apply-now-btn common_font">Apply now</button>
          </div>
        </div>
        <div className="solar-image">
          <img style={{width:"100%",height:"551px"}} src={solarfimg} alt="solarfimg" />
        </div>
      </div>

      {/* download  */}

      <div className="download-section">
        <Typography  sx={{fontSize:"39px",fontWeight:"600",fontFamily:"Poppins"}}>Download our mobile app</Typography>
        <p className="common_font" style={{color:"#2B2B2B"}}>
          Lorem ipsum dolor sit amet consectetur. Tristique adipiscing in augue
          ornare tristique dolor <br />
          condimentum facilisis libero.
        </p>
        <div className="app-preview">
          {/* Add an image of the app preview here */}
        
          <img src={donwloadImg} alt="App Preview" />
          <img src={image2} alt="image" className="download-sec-image-2" />
          <img src={image3} alt="image" className="download-sec-image-3"  />
          <button className="download-btn common_font">Download now</button>
        </div>
        <div className="app-store-links-1">
          <Typography style={{marginTop:"20px !important",color:"#274699",marginRight:"20px",fontWeight:"bold",fontSize:"25px",fontFamily:"Poppins"}}>Available in</Typography>
          <img src={playStoreIcon} style={{width:"100px",marginRight:"10  px"}} alt="Google Play" />
          <img src={appStoreIcon}  style={{width:"100px"}}  alt="App Store" />
        </div>
      </div>

      <div className="media-gallery-section">
        <Typography sx={{fontSize:"39px",fontWeight:"bold",fontFamily:"Poppins"}} >Media gallery</Typography>
        <p className="common_font">
          Lorem ipsum dolor sit amet consectetur. Tristique fermentum nunc
          volutpat <br />
           amet eleifend elit pretium turpis.
        </p>
        <div className="media-gallery">
          {/* Add images or media content here */}
          <img src={image9} alt="Media 1" className="media-gallery-img-1" />
          <img src={image10} alt="Media 2" className="media-gallery-img-2" />
        </div>
      </div>

      <div className="action-section">
        <div className="action-box">
          <img src={image54} alt="image" />
          <h3>Employee login</h3>
          <p style={{color:"#2B2B2B"}}>Lorem ipsum dolor sit amet consectetur.</p>
          <button className="action-btn common_font">Login</button>
        </div>
        <div className="action-box">
        <img src={image59} alt="image" />
          <h3>Dashboard</h3>
          <p style={{color:"#2B2B2B"}}>Lorem ipsum dolor sit amet consectetur.</p>
          <button className="action-btn common_font">Dashboard</button>
        </div>
        <div className="action-box">
        <img src={image58} alt="image" />
          <h3>Mail box</h3>
          <p style={{color:"#2B2B2B"}}>Lorem ipsum dolor sit amet consectetur.</p>
          <button className="action-btn common_font">Mail box</button>
        </div>
        <div className="action-box">
        <img src={image57} alt="image" />
          <h3>Feedback</h3>
          <p style={{color:"#2B2B2B"}}>Lorem ipsum dolor sit amet consectetur.</p>
          <button className="action-btn common_font">Feedback</button>
        </div>
      </div>


<Footer/>

    </div>
  );
};

export default HomePage;
