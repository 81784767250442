import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import homeIcon from "../assets/img/Frame 280.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Drawer from "@mui/material/Drawer";
import { Box, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const NavigationHeader = () => {
  const [drawerState, setDrawerState] = useState(false);

  const openFun = () => {
    setDrawerState(true);
  };

  const closeFun = () => {
    setDrawerState(false);
  };

  const menuJson =[
    {
      name:"Industrial feeder tripping"
    },
    {
      name:"Online new connection"
    }, {
      name:"Ease of doing buiseness"
    }, {
      name:"Solar rooftop portal"
    }, {
      name:"Ease of doing buiseness"
    }, {
      name:"RAPDRP Reports"
    }, {
      name:"Right to information"
    }, {
      name:"Finance & Accounts"
    }, {
      name:"Vigilance department"
    }, {
      name:"Project department"
    }, {
      name:"Kusum yojna"
    }, {
      name:"Schedule of rates"
    }, {
      name:"Scheme budgets"
    }, {
      name:"Pensioner’s corner"
    }, {
      name:"Medical facility"
    }, {
      name:"ESS/MSS login"
    }, {
      name:"Acts & Regulations"
    }, {
      name:"Statistics"
    }, {
      name:"Telephone directory"
    },
  ]

  return (
    <div className="navbar-container">
      <Drawer anchor="right" open={drawerState} onClose={closeFun}>
        <Box className="drawer-main-box">

         <Box onClick={closeFun} sx={{display:"flex",flexDirection:"row",alignItems:"center",  marginLeft: "45px",color:"white",marginBottom:"20px",cursor:"pointer"}}>
          <Typography className="common-font" sx={{fontSize:"16px",fontWeight:"bold",fontFamily:"Poppins"}}>Close</Typography>
          <CloseIcon/>
         </Box>
{
  menuJson.map((ele,ind)=>{
    return(
      <Box key={ind} className="drawer-sub-box">
        <Typography className="common-font" sx={{fontSize:"16px",fontFamily:"Poppins"}}>{ele.name} </Typography>
        <KeyboardArrowDownIcon sx={{fontSize:"35px"}} />
      </Box>
    )
  })
}

        </Box>
      </Drawer>
{/* <div className="navbar-main-div"> */}
<img
          style={{ width: "54px", height: "48px" }}
          src={homeIcon}
          alt="homeIcon"
        />

      <nav className="navbar">
        {/* <img
          style={{ width: "54px", height: "48px" }}
          src={homeIcon}
          alt="homeIcon"
        /> */}
        <div className="dropdown">
          <button className="nav-link">
         
            <span className="common_font">Consumer services</span> <KeyboardArrowDownIcon />
          </button>
        </div>
        <div className="dropdown">
          <button className="nav-link">
            <span className="common_font">Consumer e-seva </span>
            <KeyboardArrowDownIcon />
          </button>
        </div>
        <div className="dropdown">
          <button className="nav-link">
            <span className="common_font">Outage information</span> <KeyboardArrowDownIcon />
          </button>
        </div>
        <a href="#" className="nav-link common_font">
          Tenders
        </a>
        <a href="#" className="nav-link common_font">
          Investors
        </a>
        <a href="#" className="nav-link common_font">
          Recruitments
        </a>
        <a href="#" className="nav-link common_font">
          Tariff petitions
        </a>
        <a href="#" className="nav-link common_font">
          Consumer informations
        </a>
      </nav>
      <div className="hamburger-menu">
        <MenuIcon onClick={openFun} style={{ color: "white", fontSize: "40px" ,cursor:"pointer",marginTop:"8px"}} />
      </div>
      {/* </div> */}
    </div>
  );
};

export default NavigationHeader;
